import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import ParticleComponent from './components/particles';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Certification from './Pages/Certification';
import Education from './Pages/Education';
import Experience from './Pages/Experience';
import Project from './Pages/Project';
import Skills from './Pages/Skills';
import Volunteering from './Pages/Volunteering';
import ErrorPage from './Pages/ErrorPage';
import Achievements from './Pages/Achievements';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <ErrorPage/>
  },
  {
    path: '/About',
    element: <About />
  },
  {
    path: '/Certification',
    element: <Certification />
  },
  {
    path: '/Education',
    element: <Education />
  },
  {
    path: '/Experience',
    element: <Experience />
  },
  {
    path: '/Project',
    element: <Project />
  },
  {
    path: '/Skills',
    element: <Skills />
  },
  {
    path: '/Volunteering',
    element: <Volunteering />
  },
  {
    path: 'Achievement',
    element: <Achievements/>
  }
]);
root.render(
  <React.StrictMode>
    <ParticleComponent className="particle-background" />
    <RouterProvider router={router} />
  </React.StrictMode>
);
reportWebVitals();
