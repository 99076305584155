import React from 'react'
import Navbar from '../components/Navbar';
import Modal from '../components/Modal';
import img1 from '../components/Assets/100.gif';
import img2 from '../components/Assets/2024.gif';
import img3 from '../components/Assets/5023.gif';
import img4 from '../components/Assets/journey.jpg'
function Achievements() {
  return (
    <div>
      <div className="mt-3 mx-3" >
        <Navbar />
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center p-4" >
        <div className="row">
          <div className="col mb-4">
            <h1 className="font-weight-bolder text-left mb-4 w-100" style={{ fontFamily: "Nunito Sans, sans-serif" }}>Achievements</h1>
            <div>
              <div>
                <Modal
                  name="100 Days Batch 2024"
                  course="Leetcode"
                  image={img1}
                />
              </div>
              <div>
                <Modal
                  name="50 Days Batch 2024"
                  course="Leetcode"
                  image={img2}
                />
              </div>
              <div>
                <Modal
                  name="Teaching Assistant Journey"
                  course="Coding Ninjas"
                  image={img4}
                />
              </div>
              <div>
                <Modal
                  name="50 Days Batch 2023"
                  course="Leetcode"
                  image={img3}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Achievements