import React from 'react'
import Modal from '../components/Modal'
import Navbar from '../components/Navbar'
import { SlCalender } from 'react-icons/sl'
import BIF from '../components/Assets/BIF.jpg';
import CREATION from '../components/Assets/CREATION.png';
import ZWM from '../components/Assets/ZWM.jpeg';
import IEE from '../components/Assets/IEEPES.png';
function Volunteering() {
  return (
    <div>
      <div className="mt-3 mx-3" >
        <Navbar/>
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center p-4" >
        <div className="row">
          <div className="col mb-4">
          <h1 className="font-weight-bolder text-left mb-4 w-100" style={{ fontFamily: "Nunito Sans, sans-serif" }}>Volunteering</h1>
            <div>
            <div>
              <Modal
                name="Core-committee Member"
                course="Becoming I Foundation (VIT.V)"
                time={<><SlCalender /> 2022 - Present</>}
                image={BIF}
              />
            </div>
              <Modal
                name="Core-committee Member"
                course="Zero Waste Management (VIT.V)"
                time={<><SlCalender /> 2023 - Present</>}
                image={ZWM}
              />
            </div>
            <div>
              <Modal
                name="Core-committee Member"
                course="Creation and Innovation Labs (VIT.V) "
                time={<><SlCalender /> 2022 -2023</>}
                image={CREATION}
              />
              <Modal
                name="Core-committee Member "
                course="IEE-PES Society (VIT.V)"
                time={<><SlCalender /> 2021 -2023</>}
                image={IEE}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Volunteering