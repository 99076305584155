import React from 'react';
import { LiaTelegram } from "react-icons/lia";
function Contact() {
  return (
    <>
      <button type="button" className="buttonStyle" data-bs-toggle="modal" data-bs-target="#staticBackdrop1 ">
        <LiaTelegram style={{ color: '#3d424a', marginRight: '5px' }} />
        Contact
      </button>
      <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Send me a message</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form className="container-form">
                <div className="mb-2">
                  <label htmlFor="inputName" className="form-label">Name</label>
                  <input type="text" className="form-control custom-input" id="inputName" aria-describedby="nameHelp" placeholder='Enter your name' />
                </div>
                <div className="mb-2">
                  <label htmlFor="inputEmail" className="form-label">Email</label>
                  <input type="email" className="form-control custom-input" id="inputEmail" placeholder='Enter your email' />
                </div>
                <div className="mb-2">
                  <label htmlFor="inputMessage" className="form-label">Message</label>
                  <textarea className="form-control custom-textarea" id="inputMessage" rows="3" placeholder='Let the words flow'></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success"><LiaTelegram/>  Send Message</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
