import React from "react";

const SkillButton = ({ text, icon }) => {
  return (
    <button
      className="btn btn-outline-secondary btn-sm rounded me-2 mb-2 d-flex align-items-center"
      style={{ fontSize: "0.75rem" }} // Adjust the font size of the icon
    >
      <span className="me-2" style={{ fontSize: "1rem" }}>{icon}</span> {/* Adjust the font size of the icon within the span */}
      {text}
    </button>
  );
};

export default SkillButton;
