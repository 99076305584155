import React from 'react';
import profile from './Assets/ProfileImage.jpeg';
import Name from './Name';



const Image = () => {
  return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={profile} alt='Anant' className='rounded-circle' style={{ height: '175px', width: '175px' }} />
        <Name />
      </div>
  )
}

export default Image;
