import React from 'react'
import './Css/Name.css'
const Name = () => {
  return (
    <span>
        Anant Saxena
    </span>
  )
}

export default Name