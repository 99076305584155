import React from 'react'
import Navbar from '../components/Navbar'
import { SlCalender } from 'react-icons/sl'
import job1 from '../components/Assets/ConsultIT.jpeg';
import job2 from '../components/Assets/Coding NInjas.jpeg';
import Modal from '../components/Modal';
function Experience() {
  return (
    <div>
      <div className="mt-3 mx-3" >
        <Navbar/>
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center p-4" >
        <div className="row">
          <div className="col mb-4">
          <h1 className="font-weight-bolder text-left mb-4 w-100" style={{ fontFamily: "Nunito Sans, sans-serif" }}>Experience</h1>
            <div>
              <Modal
                name="Coding Ninjas"
                course="Teaching Assistant"
                time={<><SlCalender /> October 2023 - Janury 2024</>}
                image={job2}
              />
            </div>
            <div>
              <Modal
                name="ConsultIT"
                course="Web Developement Intern"
                time={<><SlCalender/> Aug 2023 - September 2023</>}
                image={job1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Experience;