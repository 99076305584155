import React from 'react';
import './Css/Introduction.css';
import './Js/Introduction.js'
function Loader() {
    return (
        <>
            <svg id = "logo" width="417" height="63" viewBox="0 0 642 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.82294 154.226L3.75633 157.5H7.2H26.9806H28.8053L29.3616 155.762L40.7072 120.314H89.7156L101.024 155.76L101.579 157.5H103.405H124.085H127.521L126.464 154.231L78.0914 4.63084L77.5317 2.89999H75.7127H55.9321H54.1172L53.555 4.62567L4.82294 154.226ZM72.2655 65.6147L81.0299 93.0874H49.4213L57.9535 66.4295L57.9615 66.4044L57.969 66.3791C58.6866 63.9629 59.5843 60.9756 60.6624 57.4162L60.6663 57.4034L60.6701 57.3905C61.7533 53.6713 62.8356 49.7414 63.917 45.6014C64.4051 43.9188 64.8735 42.2709 65.3224 40.6576L66.6521 45.3986C67.9657 50.082 69.0981 54.3324 70.0501 58.151L70.0589 58.1864L70.0687 58.2214C71.1249 61.987 71.8644 64.4902 72.2655 65.6147Z" stroke="#393E46" strokeWidth="5" />
                <path d="M578.755 155V157.5H581.255H600.676H603.176V155V30.1263H637H639.5V27.6263V5.39999V2.89999H637H546.01H543.51V5.39999V27.6263V30.1263H546.01H578.755V155Z" stroke="#393E46" strokeWidth="5" />
                <path d="M412.244 155V157.5H414.744H434.165H436.665V155V92.8091C436.665 91.1987 436.604 89.2503 436.485 86.9728C436.483 84.4878 436.36 81.1781 436.121 77.0632C435.881 72.9173 435.52 67.4212 435.041 60.5786C434.836 57.6012 434.599 54.2753 434.329 50.6012L502.192 156.35L502.93 157.5H504.296H522.099H524.599V155V5.39999V2.89999H522.099H502.678H500.178V5.39999V54.5543C500.178 64.974 500.298 74.8266 500.538 84.1116C500.779 93.397 501.139 101.905 501.621 109.634L501.622 109.654L501.624 109.673C501.669 110.255 501.715 110.831 501.762 111.403L434.304 4.06968L433.568 2.89999H432.187H414.744H412.244V5.39999V155Z" stroke="#393E46" strokeWidth="5" />
                <path d="M273.329 154.226L272.262 157.5H275.706H295.486H297.311L297.867 155.762L309.213 120.314H358.221L369.529 155.76L370.084 157.5H371.911H392.591H396.027L394.969 154.231L346.597 4.63084L346.037 2.89999H344.218H324.438H322.623L322.061 4.62567L273.329 154.226ZM340.771 65.6147L349.535 93.0874H317.927L326.459 66.4295L326.467 66.4044L326.475 66.3791C327.192 63.9629 328.09 60.9756 329.168 57.4162L329.172 57.4034L329.176 57.3905C330.259 53.6713 331.341 49.7415 332.423 45.6015C332.911 43.9189 333.379 42.2709 333.828 40.6576L335.158 45.3986C336.471 50.082 337.604 54.3324 338.556 58.151L338.565 58.1864L338.574 58.2214C339.63 61.987 340.37 64.4902 340.771 65.6147Z" stroke="#393E46" strokeWidth="5" />
                <path d="M143.739 155V157.5H146.239H165.659H168.159V155V92.8091C168.159 91.1987 168.099 89.2504 167.98 86.9728C167.977 84.4877 167.855 81.1781 167.616 77.0632C167.375 72.9173 167.015 67.4212 166.535 60.5786C166.33 57.6012 166.093 54.2753 165.824 50.6012L233.686 156.35L234.424 157.5H235.79H253.593H256.093V155V5.39999V2.89999H253.593H234.172H231.672V5.39999V54.5543C231.672 64.974 231.792 74.8266 232.033 84.1116C232.273 93.397 232.634 101.905 233.116 109.634L233.117 109.654L233.118 109.673C233.164 110.255 233.21 110.831 233.256 111.403L165.798 4.06968L165.063 2.89999H163.681H146.239H143.739V5.39999V155Z" stroke="#393E46" strokeWidth="5" />
            </svg>


        </>

    )
}

export default Loader;