import React from 'react'
import { FiLinkedin } from "react-icons/fi";
import { FiGithub } from "react-icons/fi";
import { FiInstagram } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import { FiMail } from "react-icons/fi";
import { TbBrandLeetcode } from "react-icons/tb";
import './Css/Icon.css';
const Icons = () => {
    return (
        <div className='d-flex flex-row'>
            <div className='icon-container linkedin-icon'>
                <a rel='noreferrer' target="_blank" href='https://www.linkedin.com/in/anant-saxena-583645225/'><FiLinkedin size={22} /></a>
            </div>
            <div className='icon-container github-icon'>
                <a rel='noreferrer' target="_blank" href='https://github.com/AnantSaxena11' ><FiGithub size={22} /></a>
            </div>
            <div className='icon-container instagram-icon'>
                <a rel='noreferrer' target="_blank" href='https://www.instagram.com/anantsax/'><FiInstagram size={22} /></a>
            </div>
            <div className='icon-container twitter-icon'>
                <a rel='noreferrer' target="_blank" href='https://x.com/11_Anant_?t=vnLpQN_mOfR5KOCrllbNzQ&s=09'><FaXTwitter size={22} /></a>
            </div>
            <div className='icon-container mail-icon'>
                <a rel='noreferrer' target="_blank" href="mailto:anantsaxena5454@gmail.com"><FiMail size={22} /></a>
            </div>
            <div className='icon-container leetcode-icon'>
                <a rel='noreferrer' target="_blank" href='https://leetcode.com/anantsaxena5454'><TbBrandLeetcode size={22}/></a>
            </div>
        </div>
    )
}

export default Icons