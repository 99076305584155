import React from "react";
import SkillButton from "./SkillButton";

const SkillDiv = (props) => {
  return (
    <div className="col-lg-10 col-md-12 col-sm-12 skill-div-container">
      <div className="border rounded p-2 skill-div" style={{ transition: "transform 0.3s" }}>
        <p className="mb-3 fw-bold" style={{ fontSize: "1.2rem" }}>{props.title}</p>
        <div className="d-flex flex-wrap">
          {props.dataelement.map((el) => (
            <SkillButton key={el.id} text={el.text} icon={el.icon} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillDiv;
