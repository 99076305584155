import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar';
import Image from '../components/Image';
import Slideup from '../components/Slideup';
import Icons from '../components/Icons';
import Buttons from '../components/Buttons';
import Footer from '../components/Footer';
import ParticleComponent from '../components/particles';
import Loader from '../components/Loader';

function Home() {
    const isFirstLoad = useRef(true); 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (isFirstLoad.current) {
            setTimeout(() => {
                isFirstLoad.current = false;
                setLoading(false);
            }, 4000);
        } else {
            setLoading(false);
        }
    }, []);

    return (
        <div className="App" style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            {
                loading ? (
                    <div className="centered-intro">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="mt-3 mx-3" style={{ flex: '1' }}>
                            <Navbar />
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center' style={{ flex: '9' }}>
                            <Image />
                            <Slideup />
                            <Icons />
                            <Buttons />
                            <ParticleComponent />
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ flex: '1' }}>
                            <Footer />
                        </div>
                    </>
                )}
        </div>
    );
}

export default Home;
