import React from 'react'
import Navbar from '../components/Navbar';
import Modal from '../components/Modal';
import image1 from '../components/Assets/Portfolio.png';
import image2 from '../components/Assets/Sorting.png';
import image3 from '../components/Assets/Snake.png';
import image4 from '../components/Assets/logo.png';
import image5 from '../components/Assets/Password.png';
import image6 from '../components/Assets/Pomo.png';
import image7 from '../components/Assets/OSRU.png';
import image8 from '../components/Assets/Bank.png';
import image9 from '../components/Assets/NATO.png';
import image10 from '../components/Assets/USA.png';
import image11 from '../components/Assets/Paddle.png';
function Project() {
  return (
    <div>
      <div className="mt-3 mx-3" style={{ flex: '1' }}>
        <Navbar />
      </div>
      <div className="d-flex flex-row justify-content-center align-items-center p-4" >
        <div className="row">
          <div className="col mb-4">
            <h1 className="font-weight-bolder text-left mb-4 w-100" style={{ fontFamily: "Nunito Sans, sans-serif" }}>Projects</h1>
            <div>
              <div>
                  <Modal
                    name="Personal Portfolio"
                    Grade="Personal Portfolio website displays all my projects and skills"
                    image={image1}
                  />
              </div>
              <div>
                <Modal
                  name="Sorting Visualizer"
                  Grade="Sorting Visualizer is a python script that visualizes various sorting algorithms like Bubble Sort, Merge Sort, Quick Sort, Merge Sort"
                  image={image2}
                />
              </div>
              <div>
                <Modal
                  name="Snake Xenzia"
                  Grade="Snake Xenzia is a python script that is a replica of the classic Snake Xenzia game. It is built using the Pygame turtle library."
                  image={image3}
                />
              </div>
              <div>
                <Modal
                  name="Tomato"
                  Grade="Tomato is a Food Delivery Website made using MERN stack. It has features like user authentication, order placement, and tracking."
                  image={image4}
                />
              </div>
              <div>
                <Modal
                  name="Password Manager"
                  Grade="Password Manager is a python script that stores all your passwords in an encrypted file. It uses the cryptography library for encryption and decryption."
                  image={image5}
                />
              </div>
              <div>
                <Modal
                  name="PomoFocus"
                  Grade="PomoFocus is a productivity app that uses the Pomodoro Technique to help you focus on your work."
                  image={image6}
                />
              </div>
              <div>
                <Modal
                  name="The Osru Magazine"
                  Grade="React Front-end for a business Magazine"
                  image={image7}
                />
              </div>
              <div>
                <Modal
                  name="Banking Website Front-End"
                  Grade="Front-end for a Banking Website made using HTML, CSS, and JavaScript"
                  image={image8}
                />
              </div>
              <div>
                <Modal
                  name="NATO - Phonetic Alphabet"
                  Grade="Python Script that can convert any text to NATO Phonetic Alphabet"
                  image={image9}
                />
              </div>
              <div>
                <Modal
                  name="USA State Game"
                  Grade="Python Script that quizzes you on the names of the states in the USA"
                  image={image10}
                />
              </div>
              <div>
                <Modal
                  name="Paddle Game"
                  Grade="Python Script that is a replica of the classic Paddle Game. It is built using the Pygame Turtle library."
                  image={image11}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project