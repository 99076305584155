import React from 'react'

const Footer = () => {
  return (
    <span className='font-weight-light' style={{ fontFamily: 'Nunito Sans, sans-serif', color: '#858585', fontSize: '15px' }}>
      Copyright © 2024 <a href='/' style={{ color: '#858585', textDecoration: 'none' }}>Anant Saxena</a>
    </span>
  )
}

export default Footer