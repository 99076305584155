import React, { useState, useEffect } from 'react';
import './Css/Slideup.css'; // import your CSS file

const texts = ['Software Engineer','Web Developer','Data Analyst','Teaching Assistant']; // your texts

const Slideup = () => {
  const [activeText, setActiveText] = useState('Software Engineer');

  useEffect(() => {
    const changeText = () => {
      const randomText = texts[Math.floor(Math.random() * texts.length)];
      setActiveText(randomText);
    };

    changeText(); // call the function once immediately

    const interval = setInterval(changeText, 1500); // then call it every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return <div className="slideup font-weight-light" style={{fontSize: '1.35rem',fontFamily: 'Nunito Sans", sans-serif'}}>{activeText}</div>;
};

export default Slideup;