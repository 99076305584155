import React, { useState, useEffect, useRef } from "react";
import { FiMenu, FiHome, FiUser, FiBookOpen, FiTool, FiCode, FiSmile, FiX, FiBriefcase } from "react-icons/fi";
import { LuMedal } from "react-icons/lu";
import { Link, useLocation } from "react-router-dom";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuHovered, setMenuHovered] = useState(false); // Added state for menu hover
  const modalRef = useRef(null);
  const location = useLocation();

  const MenuItemButton = ({ name, icon, link, closeMenu }) => {
    return (
      <Link to={link} className={`nav-link text-dark d-flex align-items-center ${location.pathname === link ? 'selected' : ''}`} onClick={closeMenu}>
        <span style={{ fontSize: "10px", marginBottom: "3px", marginRight: "4px" }}>{icon}</span>
        {name}
      </Link>
    );
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div className="position-relative">
      <div
        className="menu-button-wrapper"
        style={{
          width: "30px", // Set the width of the clickable area
          height: "30px", // Set the height of the clickable area
          backgroundColor: isOpen ? "#e2e8f0" : menuHovered ? "#e2e8f0" : "transparent", // Conditional background color
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "background-color 0.3s ease",
          borderRadius: "5px"
        }}
        onClick={toggleModal}
        onMouseEnter={() => setMenuHovered(true)} // Set menuHovered state to true on mouse enter
        onMouseLeave={() => setMenuHovered(false)} // Set menuHovered state to false on mouse leave
      >
        {isOpen ? <FiX style={{ fontSize: "20px" }} /> : <FiMenu style={{ fontSize: "20px" }} />}
      </div>
      {isOpen && (
        <div
          ref={modalRef}
          className="position-absolute top-100 start-0"
          style={{
            zIndex: 999,
            backgroundColor: "#ffff",
            border: "#e2e8f0",
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <ul className="navbar-list" style={{ padding: 0, listStyleType: "none", margin: 0, height: "225px", width: "150px" }}>
            <li style={{ width: "100%" }}> {/* Set width to 100% for each list item */}
              <MenuItemButton name="Home" icon={<FiHome style={{ fontSize: "15px", marginBottom: "3px" }} />} link="/" closeMenu={closeMenu} />
            </li>
            <li style={{ width: "100%" }}>
              <MenuItemButton name="About" icon={<FiUser style={{ fontSize: "15px", marginBottom: "3px" }} />} link="/about" closeMenu={closeMenu} />
            </li>
            <li style={{ width: "100%" }}>
              <MenuItemButton name="Education" icon={<FiBookOpen style={{ fontSize: "15px", marginBottom: "3px" }} />} link="/education" closeMenu={closeMenu} />
            </li>
            <li style={{ width: "100%" }}>
              <MenuItemButton name="Experience" icon={<FiBriefcase style={{ fontSize: "15px", marginBottom: "3px" }} />} link="/experience" closeMenu={closeMenu} />
            </li>
            <li style={{ width: "100%" }}>
              <MenuItemButton name="Skills" icon={<FiTool style={{ fontSize: "15px", marginBottom: "3px" }} />} link="/skills" closeMenu={closeMenu} />
            </li>
            <li style={{ width: "100%" }}>
              <MenuItemButton name="Projects" icon={<FiCode style={{ fontSize: "15px", marginBottom: "3px" }} />} link="/project" closeMenu={closeMenu} />
            </li>
            <li style={{ width: "100%" }}>
              <MenuItemButton name="Volunteering" icon={<FiSmile style={{ fontSize: "15px", marginBottom: "3px" }} />} link="/volunteering" closeMenu={closeMenu} />
            </li>
            <li style={{ width: "100%" }}>
              <MenuItemButton name="Achievements" icon={<LuMedal style={{ fontSize: "15px", marginBottom: "3px" }} />} link="/achievement" closeMenu={closeMenu} />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NavBar;
