import React from 'react'
import './Css/Buttons.css';

import Contact from './Contact';
import Resume from './Resume';
const Buttons = () => {
  return (
    <div className='d-flex flex-row justify-content-between'>
      <Contact />
      <Resume  direction = 'https://drive.google.com/file/d/1HXMwKCtMpWks_UFNky_8mM3ExcO6i1kv/preview' title = "My Resume"/>
    </div>
  )
}

export default Buttons;