
import { FaJava, FaGitAlt, FaGithub, FaBrain, FaLightbulb } from "react-icons/fa";

import { RiReactjsLine, RiTeamFill } from "react-icons/ri";
import {
    SiBootstrap,
    SiTailwindcss,
    SiMysql,
    SiDocker,
    SiWindows11,
} from "react-icons/si";

import { TbSql } from "react-icons/tb";

import { MdLeaderboard } from "react-icons/md";
import { FaLinux } from "react-icons/fa";

import { SiCplusplus, SiPython } from "react-icons/si";

export const programming_languages = [
  { id: 1, text: 'C++', icon: <SiCplusplus/> },
  { id: 2, text: 'Python', icon: <SiPython/> },
  { id: 3, text: 'Java', icon: <FaJava/> },
];

export const technologies_frameworks = [
  { id: 1, text: 'React', icon: <RiReactjsLine/> },
  { id: 2, text: 'Bootstrap', icon: <SiBootstrap/> },
  { id: 3, text: 'Tailwind CSS', icon: <SiTailwindcss/> },
  { id: 4, text: 'MySQL' ,icon: <SiMysql/>}
];

export const developer_tools = [
  { id: 1, text: 'SQL', icon: <TbSql/> },
  { id: 2, text: 'Git', icon: <FaGitAlt/> },
  { id: 3, text: 'GitHub', icon: <FaGithub/> },
  { id: 4, text: 'Docker', icon: <SiDocker/> },
];
export const soft_skills = [
  { id: 1, text: 'Teamwork', icon: <RiTeamFill/> },
  { id: 2, text: 'Problem Solving', icon: <FaBrain/> },
  { id: 3, text: 'Creativity', icon: <FaLightbulb/> },
  { id: 3, text: 'Leadership Skills', icon: <MdLeaderboard/>}
];

export const platforms = [
  { id: 1, text: 'Windows', icon: <SiWindows11/> },
  { id: 2, text: 'Linux', icon: <FaLinux/> },
];





