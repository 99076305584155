import React from 'react'
import { Link } from 'react-router-dom';
function ErrorPage() {
  return (
    <>
    <div>404 Not Found</div>
    <Link to ="/">Home</Link>
    </>
  )
}

export default ErrorPage;